<template>
    <div>
        <div>
            <h3>{{ $t("add_company_details") }}</h3>
            <p>
                {{ $t("we_need_some_basic_data_from_you") }}
            </p>
            <validation-observer
                ref="companyRegisterValidation"
                name="RegisterCompany"
            >
                <div id="alert-errors">
                    <b-row class="col-md-12 pr-0 mr-0" v-if="errors.length > 0">
                        <div class="alert alert-danger col-md-12 pr-0">
                            <div class="p-2">
                                <div v-for="(err, index) in errors" :key="index">
                                    <b-avatar variant="light-danger" size="45">
                                        <feather-icon size="21" icon="AlertTriangleIcon"/>
                                    </b-avatar>
                                    {{ $t(err) }}
                                </div>
                            </div>
                        </div>
                    </b-row>
                </div>

                <b-form @submit="onSubmit" @reset="onReset">
                    <b-card class="col-md-12">
                        <b-row m-0 p-0>
                            <b-col cols="12" xl="6" md="6" lg="6" id="marta">
                                <b-form-group
                                    id="domicile-country-group"
                                    :label="$t('domicile_country') + ':'"
                                    label-for="domicile-country"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Country"
                                        rules="required"
                                    >
                                        <b-form-input
                                            v-model.trim="country_id"
                                            :state="errors.length > 0 ? false : null"
                                            required
                                            disabled
                                            id="domicile-country"
                                        >
                                        </b-form-input>

                                        <small class="text-danger">{{ $t(errors[0]) }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <b-form-group
                                    id="company-name-group"
                                    :label="$t('company_name') + ':'"
                                    label-for="company-name"
                                >
                                    <vue-autosuggest
                                        :suggestions="filteredOptions"
                                        :limit="10"
                                        v-model.trim="company_name"
                                        :input-props="{
                      id: 'autosuggest__input',
                      class: 'form-control',
                      placeholder: $t('select_company'),
                    }"
                                        @selected="onSelected"
                                        :get-suggestion-value="getSuggestionValue"
                                        @keyup="searchForCompaniesName"
                                    >
                                        <template slot-scope="{ suggestion }">
                      <span class="my-suggestion-item"
                      >{{ suggestion.item }}
                      </span>
                                        </template>
                                    </vue-autosuggest>
                                    <b-button
                                        variant="primary"
                                        v-if="showHint"
                                        class="mt-1"
                                        disabled
                                    >
                                        <b-spinner small type="grow"></b-spinner>
                                        {{ $t("loading") }}...
                                    </b-button>
                                    <div
                                        class="alert alert-secondary p-1 mt-1"
                                        v-if="companyMessage"
                                    >
                                        <span v-if="currentLanguage == 'en'"> If you can not find your company on the list please contact administrator at <strong>support@aequitex.com</strong> or you can <a @click="openLink()" style="text-decoration:underline;" class="font-weight-bolder">book a meeting here!</a></span>

                                        <span v-else-if="currentLanguage == 'de'">
                      Falls Sie Ihre Firma in dieser Liste nicht finden können, kontaktieren Sie bitte den Administrator über <strong>support@aequitex.com</strong>  oder <a @click="openLink()" style="text-decoration:underline;" class="font-weight-bolder">buchen Sie sich hier Ihr persönliches Video-Meeting</a>.
                    </span>
                                    </div>
                                    <!-- <div
                                      class="alert alert-danger p-1 mt-1 is-invalid"
                                      v-if="alreadyExists.length > 0"
                                    >
                                      {{ $t(alreadyExists) }}
                                    </div> -->
                                    <b-row class="d-flex">
                                        <b-col class="text-start pt-1" aling="start">

                                            {{ $t("company_uid") }}:
                                            <b>{{ companies[company_name] }}</b>

                                        </b-col>
                                        <b-col class="text-right pt-1" aling="end">
                                            <b-button
                                                variant="primary"
                                                @click="getCompanyDataFromZefix()"
                                                :disabled="showLoader"
                                                v-if="companies[company_name] != null"
                                            >
                                                <feather-icon icon="DownloadIcon"/>
                                                {{ $t("proceed") }}
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                    <span v-if="waitForDebtorData">
                      <div v-if="currentLanguage == 'en'" class="alert alert-secondary p-1">
                        Please be patient, loading the data might need 5 until 10 seconds.
                      </div>
                      <div v-else-if="currentLanguage == 'de'" class="alert alert-secondary p-1">
                        Bitte haben Sie etwas Geduld, das Laden der Daten kann 5 bis 10 Sekunden dauern
                      </div>
                    </span>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" xl="6" md="6" lg="6">
                                <img src="/new-loader.svg" v-if="showLoader"/>
                            </b-col>
                        </b-row>
                    </b-card>
                    <div v-if="showRestData">
                        <b-card>
                            <b-row m-0 p-0>
                                <b-col cols="12" xl="6" md="6" lg="6">
                                    <b-form-group
                                        id="company-name-field-group"
                                        :label="$t('company_name') + ':'"
                                        label-for="company-name-field"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Company Name"
                                            rules="required"
                                        >
                                            <b-input-group class="input-group-merge">
                                                <b-input-group-prepend
                                                    is-text
                                                    :class="
                            company_name_after.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                                                >
                                                    <feather-icon
                                                        icon="HomeIcon"
                                                        class="cursor-pointer"
                                                    />
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    :class="
                            company_name_after.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                                                    id="company-name-field"
                                                    v-model.trim="company_name_after"
                                                    required
                                                    :state="errors.length > 0 ? false : null"
                                                    :disabled="disableInputs"
                                                ></b-form-input>
                                            </b-input-group>

                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" xl="4" md="4" lg="4">
                                    <b-form-group
                                        id="company-address-group"
                                        :label="$t('company-address') + ':'"
                                        label-for="company-address"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Company Address"
                                            rules="required"
                                        >
                                            <b-form-input
                                                :class="
                                                  company_address ? company_address.length > 0
                                                    ? ''
                                                    : showBlueBorder
                                                    ? 'is-empty'
                                                    : '' : ''
                                                "
                                                id="company-address"
                                                v-model.trim="company_address"
                                                :state="errors.length > 0 ? false : null"
                                                required
                                                :disabled="disableInputs"
                                            ></b-form-input>
                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" xl="2" md="2" lg="2">
                                    <b-form-group
                                        id="company-address-nr-group"
                                        :label="$t('company-address-nr') + ':'"
                                        label-for="company-address-nr"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Company Address Nr"
                                            rules="required"
                                        >
                                            <b-form-input
                                                id="company-address-nr"
                                                :class="
                          company_address_nr ? company_address_nr.length > 0
                            ? ''
                            : showBlueBorder
                            ? 'is-empty'
                            : '' : ''
                        "
                                                v-model.trim="company_address_nr"
                                                required
                                                :state="errors.length > 0 ? false : null"
                                                :disabled="disableInputs"
                                            ></b-form-input>
                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row m-0 p-0>
                                <b-col cols="12" xl="6" md="6" lg="6">
                                    <b-form-group
                                        id="company-uid-group"
                                        :label="
                      $t('company_uid ') + '(CHE-397.517.219 > CHE397517219):'
                    "
                                        label-for="company-uid"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Company UID"
                                            rules="required"
                                        >
                                            <b-form-input
                                                id="company-uid"
                                                :class="
                          company_uid.length > 0
                            ? ''
                            : showBlueBorder
                            ? 'is-empty'
                            : ''
                        "
                                                v-model.trim="company_uid"
                                                disabled
                                                required
                                                :state="errors.length > 0 ? false : null"
                                            ></b-form-input>
                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" xl="2" md="2" lg="2">
                                    <b-form-group
                                        id="company-zip-group"
                                        :label="$t('zip') + ':'"
                                        label-for="company-zip"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Company Zip"
                                            rules="required"
                                        >
                                            <b-form-input
                                                :class="
                          company_zip? company_zip.length > 0
                            ? ''
                            : showBlueBorder
                            ? 'is-empty'
                            : '' : ''
                        "
                                                id="company-zip"
                                                v-model.trim="company_zip"
                                                required
                                                :state="errors.length > 0 ? false : null"
                                                :disabled="disableInputs"
                                            ></b-form-input>
                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" xl="4" md="4" lg="4">
                                    <b-form-group
                                        id="company-city-group"
                                        :label="$t('city') + ':'"
                                        label-for="company-city"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Company City"
                                            rules="required"
                                        >
                                            <b-form-input
                                                id="company-city"
                                                :class="
                          company_city? company_city.length > 0
                            ? ''
                            : showBlueBorder
                            ? 'is-empty'
                            : '' : ''
                        "
                                                v-model.trim="company_city"
                                                required
                                                :state="errors.length > 0 ? false : null"
                                                :disabled="disableInputs"
                                            ></b-form-input>
                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row m-0 p-0>
                                <b-col cols="12" xl="6" md="6" lg="6">
                                    <b-form-group
                                        id="company-legal-form-group"
                                        :label="$t('legal_form') + ':'"
                                        label-for="company-legal-form"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Company Legal Form"
                                            rules="required"
                                        >
                                            <b-form-select
                                                id="company-legal-form"
                                                v-model.trim="company_legal_form"
                                                :class="
                          company_legal_form
                            ? ''
                            : showBlueBorder
                            ? 'is-empty'
                            : ''
                        "
                                                :options="[
                          { value: 0, text: $t('sole_proprietorship') },
                          { value: 1, text: $t('general_partnership') },
                          { value: 2, text: $t('limited_partnership') },
                          { value: 3, text: $t('corporation') },
                          { value: 4, text: $t('limited_liability_company') },
                          { value: 5, text: $t('cooperative') },
                          { value: 6, text: $t('cantonal_public_company')},
                          { value: 7, text: $t('association')}
                         ]"
                                                required
                                                :state="errors.length > 0 ? false : null"
                                                :disabled="disableInputs"
                                            ></b-form-select>
                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" xl="6" md="6" lg="6">
                                    <b-form-group
                                        id="language_of_correspondence'"
                                        :label="$t('language_of_correspondence') + ':'"
                                        label-for="language_of_correspondence'"
                                    >

                                        <validation-provider
                                            #default="{ errors }"
                                            name="language_of_correspondence"
                                            rules="required"
                                        >
                                            <b-input-group class="input-group-merge">

                                                <b-form-select
                                                    :class="
                        language &&  language.length > 0
                            ? ''
                            : showBlueBorder
                            ? 'is-empty'
                            : ''
                        "
                                                    :options="[
                          { text: $t('german'), value: 'de' },
                          { text: $t('english'), value: 'en' },
                        ]"
                                                    id="language_of_correspondence"
                                                    v-model.trim="language"
                                                    required
                                                ></b-form-select>
                                            </b-input-group>
                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card>
                        <h5>{{ $t("financial_data") }}</h5>
                        <b-card>
                            <b-row m-0 p-0>
                                <b-col cols="12" xl="6" md="6" lg="6">
                                    <b-form-group
                                        id="company-established-at-group"
                                        :label="$t('established_since') + ':'"
                                        label-for="company-established-at-field"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Established since"
                                            rules="required"
                                        >
                                            <b-form-datepicker
                                                no-flip
                                                id="company-established-at-field"
                                                v-model.trim="company_established_at"
                                                :class="
                          company_established_at ? company_established_at.length > 0
                            ? ''
                            : showBlueBorder
                            ? 'is-empty'
                            : '' : ''
                        "
                                                :state="errors.length > 0 ? false : null"
                                                :disabled="disableInputs"
                                                required
                                            ></b-form-datepicker>
                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" xl="6" md="6" lg="6">
                                    <b-form-group
                                        id="company-number-of-employees-group"
                                        :label="$t('number_of_employees') + ':'"
                                        label-for="company-number-of-employees"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Number of employees"
                                            rules="required"
                                        >
                                            <b-form-input
                                                id="company-number-of-employees"
                                                v-model.trim="number_of_employees"
                                                type="number"
                                                :class="
                          number_of_employees.length > 0
                            ? ''
                            : showBlueBorder
                            ? 'is-empty'
                            : ''
                        "
                                                required
                                                :state="errors.length > 0 ? false : null"
                                                :disabled="disableInputs"
                                            ></b-form-input>
                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row m-0 p-0>
                                <b-col cols="12" xl="6" md="6" lg="6">
                                    <b-form-group
                                        :label="$t('annual_turnover') + ':'"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Annual turnover"
                                            rules="required"
                                        >
                                            <b-input-group>
                                                <b-input-group-prepend id="selectedCurrency">

                                                    <b-form-select v-model="selected_currency" :options="currencies" :disabled="disableInputs">

                                                    </b-form-select>
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    v-model="annual_turnover"
                                                    class="form-control"
                                                    :state="errors.length > 0 ? false : null"
                                                >
                                                </b-form-input>
                                            </b-input-group>
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" xl="6" md="6" lg="6">
                                    <b-form-group
                                        :label="$t('open_debt_enforcement_against_you') + '?'"
                                        v-slot="{ ariaDescribedby }"
                                    >
                                        <b-form-radio-group
                                            id="company-debt-enforcement"
                                            v-model.trim="debt_enforcement"
                                            :class="
                        debt_enforcement.length > 0
                          ? ''
                          : showBlueBorder
                          ? 'is-empty'
                          : ''
                      "
                                            :options="debt_enforcement_values"
                                            :aria-describedby="ariaDescribedby"
                                            name="debt-enforcement"
                                        ></b-form-radio-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row m-0 p-0>


                            </b-row>
                        </b-card>
                        <h5>{{ $t("main_contact_person") }}</h5>
                        <b-card>
                            <b-row m-0 p-0>
                                <b-col cols="12" xl="6" md="6" lg="6">
                                    <b-form-group
                                        id="main-contact-gender"
                                        :label="$t('salutation') + ':'"
                                        label-for="main-contact-gender"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="main-contact-gender"
                                            rules="required"
                                        >
                                            <b-input-group class="input-group-merge">
                                                <b-input-group-prepend
                                                    is-text
                                                    :class="
                            main_contact_gender.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                                                >
                                                    <feather-icon
                                                        icon="UserIcon"
                                                        class="cursor-pointer"
                                                    />
                                                </b-input-group-prepend>
                                                <b-form-select
                                                    id="main-contact-gender"
                                                    :class="
                            main_contact_gender.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                                                    v-model.trim="main_contact_gender"
                                                    :options="[
                            {
                              text: $t('select_one'),
                              value: null,
                              disabled: true,
                            },
                            { text: $t('mr'), value: '0' },
                            { text: $t('mrs'), value: '1' },
                          ]"
                                                    required
                                                    :state="errors.length > 0 ? false : null"
                                                ></b-form-select>
                                            </b-input-group>
                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" xl="6" md="6" lg="6">
                                    <b-form-group
                                        id="main-contact-email-group"
                                        :label="$t('company_email_address') + ':'"
                                        label-for="main-contact-email"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Number of Debtors"
                                            rules="required|email"
                                        >
                                            <b-input-group class="input-group-merge">
                                                <b-input-group-prepend
                                                    is-text
                                                    :class="
                            main_contact_email.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                                                >
                                                    <feather-icon
                                                        icon="MailIcon"
                                                        class="cursor-pointer"
                                                    />
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    id="main-contact-email"
                                                    v-model.trim="main_contact_email"
                                                    :class="
                            main_contact_email.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                                                    required
                                                    disabled
                                                    :state="errors.length > 0 ? false : null"
                                                ></b-form-input>
                                            </b-input-group>
                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row m-0 p-0>
                                <b-col cols="12" xl="6" md="6" lg="6">
                                    <b-form-group
                                        id="main-contact-first-name-group"
                                        :label="$t('first_name') + ':'"
                                        label-for="main-contact-first-name"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="First name"
                                            rules="required"
                                        >
                                            <b-input-group class="input-group-merge">
                                                <b-input-group-prepend
                                                    is-text
                                                    :class="
                            main_contact_first_name.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                                                >
                                                    <feather-icon
                                                        icon="UserIcon"
                                                        class="cursor-pointer"
                                                    />
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    id="main-contact-first-name"
                                                    v-model.trim="main_contact_first_name"
                                                    required
                                                    :class="
                            main_contact_first_name.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                                                    :state="errors.length > 0 ? false : null"
                                                    :disabled="disableInputs"
                                                ></b-form-input>
                                            </b-input-group>

                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" xl="6" md="6" lg="6">
                                    <b-form-group
                                        id="main-contact-tel-group"
                                        :label="$t('mobile') + ':'"
                                        label-for="main-contact-tel"
                                    >
                                        <validation-provider
                                            ref="phoneValidator"
                                            #default="{ errors }"
                                            name="phoneValidation"
                                            rules="required"
                                        >
                                            <b-input-group>
                                                <b-input-group-prepend
                                                    :class="
                            main_contact_tel.length > 0
                              ? 'phoneCountries '
                              : showBlueBorder
                              ? 'phoneCountries is-empty'
                              : 'phoneCountries'
                          "
                                                    id="phoneCountries"
                                                >
                                                    <b-form-select
                                                        :options="countries"
                                                        v-model.trim="phoneContries"
                                                    >
                                                    </b-form-select>
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    id="main-contact-tel"
                                                    v-model.trim="main_contact_tel"
                                                    @input="checkPhoneFormat"
                                                    class="form-control"
                                                    :state="errors.length > 0 ? false : null"
                                                    :disabled="disableInputs"
                                                    :class="
                            main_contact_tel.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                                                >
                                                </b-form-input>
                                            </b-input-group>

                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                            <small
                                                class="text-danger is-invalid"
                                                v-if="phoneValidation"
                                            >{{ phoneValidation }}
                                            </small
                                            >
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row m-0 p-0>
                                <b-col cols="12" xl="6" md="6" lg="6">
                                    <b-form-group
                                        id="main-contact-last-name-group"
                                        :label="$t('last_name') + ':'"
                                        label-for="main-contact-last-name"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Last Name"
                                            rules="required"
                                        >
                                            <b-input-group class="input-group-merge">
                                                <b-input-group-prepend
                                                    is-text
                                                    :class="
                            main_contact_last_name.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                                                >
                                                    <feather-icon
                                                        icon="UserIcon"
                                                        class="cursor-pointer"
                                                    />
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    id="main-contact-last-name"
                                                    v-model.trim="main_contact_last_name"
                                                    :class="
                            main_contact_last_name.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                                                    required
                                                    :state="errors.length > 0 ? false : null"
                                                    :disabled="disableInputs"
                                                ></b-form-input>
                                            </b-input-group>

                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" xl="6" md="6" lg="6">
                                    <b-form-group
                                        id="main-contact-position-group"
                                        :label="$t('position_in_the_company') + ':'"
                                        label-for="main-contact-position"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Position in the Company"
                                            rules="required"
                                        >
                                            <b-input-group class="input-group-merge">
                                                <b-input-group-prepend
                                                    is-text
                                                    :class="
                            main_contact_position.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                                                >
                                                    <feather-icon
                                                        icon="BriefcaseIcon"
                                                        class="cursor-pointer"
                                                    />
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    id="main-contact-position"
                                                    v-model.trim="main_contact_position"
                                                    :class="
                            main_contact_position.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                                                    required
                                                    :state="errors.length > 0 ? false : null"
                                                    :disabled="disableInputs"
                                                ></b-form-input>
                                            </b-input-group>

                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row m-0 p-0>
                                <b-col cols="12" xl="6" md="6" lg="6">
                                    <b-form-group
                                        id="signing-authority-type-group"
                                        :label="$t('signatory_rights') + ':'"
                                        label-for="signing-authority-type"
                                        rules="required"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="signing-authority-type-group"
                                            rules="required"
                                        >
                                            <b-input-group class="input-group-merge">
                                                <b-input-group-prepend
                                                    is-text
                                                    :class="
                            signing_authority_type.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                                                >
                                                    <feather-icon
                                                        icon="UserPlusIcon"
                                                        class="cursor-pointer"
                                                    />
                                                </b-input-group-prepend>
                                                <b-form-select
                                                    id="signing-authority-type"
                                                    v-model.trim="signing_authority_type"
                                                    :class="
                            signing_authority_type.length > 0
                              ? ''
                              : showBlueBorder
                              ? 'is-empty'
                              : ''
                          "
                                                    @change="hideSignersPart"
                                                    :options="[
                            {
                              text: $t('select_one'),
                              value: null,
                              disabled: true,
                            },
                            { text: $t('no_signatory'), value: '2' },
                            { text: $t('sole_signatory'), value: '0' },
                            { text: $t('collectively_by_two'), value: '1' },

                          ]"
                                                    required
                                                    :state="errors.length > 0 ? false : null"
                                                ></b-form-select>
                                            </b-input-group>
                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card>

                        <div v-if="hideSigners">
                            <h5 v-if="signators.length > 0 && hideSigners">
                                {{ $t("other_signers") }}
                            </h5>
                            <b-card v-for="(signator, index) in signators" :key="index">
                                <b-row m-0 p-0>
                                    <b-col cols="12" xl="6" md="6" lg="6">
                                        <b-form-group
                                            :id="index + 'signator-gender-group'"
                                            :label="$t('salutation') + ':'"
                                            :label-for="index + 'signator-gender'"
                                        >
                                            <b-input-group class="input-group-merge">
                                                <b-input-group-prepend is-text>
                                                    <feather-icon
                                                        icon="UserIcon"
                                                        class="cursor-pointer"
                                                    />
                                                </b-input-group-prepend>
                                                <b-form-select
                                                    :id="index + 'signator-gender'"
                                                    v-model.trim="signator.gender"
                                                    :options="[
                            {
                              text: $t('select_one'),
                              value: null,
                              disabled: true,
                            },
                            { text: $t('mr'), value: '0' },
                            { text: $t('mrs'), value: '1' },
                          ]"
                                                    required
                                                ></b-form-select>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" xl="6" md="6" lg="6">
                                        <b-form-group
                                            :id="index + 'signator-email-group'"
                                            :label="$t('company_email_address') + ':'"
                                            :label-for="'signator-email'"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                :name="' Email'"
                                                rules="required|email"
                                            >
                                                <b-input-group class="input-group-merge">
                                                    <b-input-group-prepend is-text>
                                                        <feather-icon
                                                            icon="MailIcon"
                                                            class="cursor-pointer"
                                                        />
                                                    </b-input-group-prepend>
                                                    <b-form-input
                                                        @change="validateEmail"
                                                        :id="index + 'signator-email'"
                                                        v-model.trim="signator.email"
                                                        required
                                                        :state="errors.length > 0 ? false : null"
                                                        :disabled="disableInputs"
                                                    ></b-form-input>
                                                </b-input-group>

                                                <small
                                                    class="text-danger"
                                                    v-if="emailValidationsError"
                                                >{{ emailValidationsError }}
                                                </small
                                                >
                                                <small class="text-danger">{{ $t(errors[0]) }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row m-0 p-0>
                                    <b-col cols="12" xl="6" md="6" lg="6">
                                        <b-form-group
                                            :id="index + 'signator-first-name-group'"
                                            :label="$t('first_name') + ':'"
                                            :label-for="index + 'signator-first-name'"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                :name="' First Name'"
                                                rules="required"
                                            >
                                                <b-input-group class="input-group-merge">
                                                    <b-input-group-prepend is-text>
                                                        <feather-icon
                                                            icon="UserIcon"
                                                            class="cursor-pointer"
                                                        />
                                                    </b-input-group-prepend>
                                                    <b-form-input
                                                        :id="index + 'signator-first-name'"
                                                        v-model.trim="signator.name"
                                                        required
                                                        :state="errors.length > 0 ? false : null"
                                                        :disabled="disableInputs"
                                                    ></b-form-input>
                                                </b-input-group>

                                                <small class="text-danger">{{ $t(errors[0]) }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" xl="6" md="6" lg="6">
                                        <b-form-group
                                            :id="index + 'signator-tel-group'"
                                            :label="$t('mobile') + ':'"
                                            :label-for="index + 'signator-tel'"
                                        >
                                            <b-input-group class="">
                                                <b-input-group-prepend
                                                    class="phoneCountries"
                                                    id="phoneCountries"
                                                >
                                                    <b-form-select
                                                        :options="countries"
                                                        v-model.trim="signator.iso_code"
                                                    >
                                                    </b-form-select>
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    :id="index + 'signator-tel'"
                                                    v-model.trim="signator.tel"
                                                    @input="
                            checkPhoneFormatSignator(
                              signator.tel,
                              signator.iso_code
                            )
                          "
                                                    class="form-control"
                                                    :disabled="disableInputs"
                                                >
                                                </b-form-input>
                                            </b-input-group>
                                            <span
                                                v-if="phoneSignatorValidation"
                                                class="text-danger"
                                            >{{ phoneSignatorValidation }}</span
                                            >
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row m-0 p-0>
                                    <b-col cols="12" xl="6" md="6" lg="6">
                                        <b-form-group
                                            :id="index + 'signator-last-name-group'"
                                            :label="$t('last_name') + ':'"
                                            :label-for="index + 'signator-last-name'"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                :name="' Last Name'"
                                                rules="required"
                                            >
                                                <b-input-group class="input-group-merge">
                                                    <b-input-group-prepend is-text>
                                                        <feather-icon
                                                            icon="UserIcon"
                                                            class="cursor-pointer"
                                                        />
                                                    </b-input-group-prepend>
                                                    <b-form-input
                                                        :id="index + 'signator-last-name'"
                                                        v-model.trim="signator.last_name"
                                                        required
                                                        :state="errors.length > 0 ? false : null"
                                                        :disabled="disableInputs"
                                                    ></b-form-input>
                                                </b-input-group>

                                                <small class="text-danger">{{ $t(errors[0]) }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" xl="6" md="6" lg="6">
                                        <b-form-group
                                            :id="index + 'signator-position-group'"
                                            :label="$t('position_in_the_company') + ':'"
                                            :label-for="index + 'signator-position'"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                :name="' Position'"
                                                rules="required"
                                            >
                                                <b-input-group class="input-group-merge">
                                                    <b-input-group-prepend is-text>
                                                        <feather-icon
                                                            icon="BriefcaseIcon"
                                                            class="cursor-pointer"
                                                        />
                                                    </b-input-group-prepend>
                                                    <b-form-input
                                                        :id="index + 'signator-position'"
                                                        v-model.trim="signator.position"
                                                        required
                                                        :state="errors.length > 0 ? false : null"
                                                        :disabled="disableInputs"
                                                    ></b-form-input>
                                                </b-input-group>

                                                <small class="text-danger">{{ $t(errors[0]) }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row m-0 p-0>
                                    <b-col cols="12" xl="6" md="6" lg="6">
                                        <b-form-group
                                            :id="index + 'signator-signing-authority-type-group'"
                                            :label="$t('signing_authority_type') + ':'"
                                            :label-for="index + 'signator-signing-authority-type'"
                                        >
                                            <b-input-group class="input-group-merge">
                                                <b-input-group-prepend is-text>
                                                    <feather-icon
                                                        icon="UserPlusIcon"
                                                        class="cursor-pointer"
                                                    />
                                                </b-input-group-prepend>
                                                <b-form-select
                                                    :id="index + 'signator-signing-authority-type'"
                                                    v-model.trim="signator.signing_authority_type"
                                                    :options="[
                            {
                              text: $t('select_one'),
                              value: null,
                              disabled: true,
                            },
                            { text: $t('no_signatory'), value: '2' },
                            { text: $t('sole_signatory'), value: '0' },
                            { text: $t('collectively_by_two'), value: '1' },
                          ]"
                                                    required
                                                ></b-form-select>
                                            </b-input-group>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" xl="6" md="6" lg="6" class="text-right pt-2">
                                        <b-button
                                            variant="outline-danger"
                                            @click="deleteSignator(index, signator)"
                                        >
                                            <feather-icon icon="DeleteIcon"></feather-icon>
                                            {{ $t("delete") }}
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-card>
                            <b-row m-0 p-0 v-if="signators.length < 5">
                                <b-col cols="12" class="text-right">
                                    <b-button variant="outline-primary" @click="addNewSigner()">
                                        <feather-icon icon="PlusIcon"></feather-icon>
                                        {{ $t("add_signatory") }}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </div>


                        <h5>{{ $t("fee_policy") }}</h5>
                        <b-card>
                            <b-row m-0 p-0>
                                <b-col cols="12">
                                    <!-- terms-and-conditions -->
                                    <b-form-group>
                                        <validation-provider
                                            #default="{ errors }"
                                            name="data-privacy"
                                            rules="required"
                                        >
                                            <b-form-checkbox
                                                id="data-privacy"
                                                v-model.trim="data_privacy"
                                                name="data-privacy"
                                                :state="errors.length > 0 ? false : null"
                                                :disabled="disableInputs"
                                            >
                                                {{ $t("agree_with_policy_seller") }}
                                            </b-form-checkbox>
                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                        </validation-provider>
                                        <div v-if="!preregistered"
                                             style="background: #efefef; padding: 10px"
                                             class="mt-1"
                                        >
                                            <p>
                                                {{ $t("agree_with_policy_seller_subtitle") }}

                                                <!-- Diese AGB's gelten. Wir machen Sie insbesondere darauf aufmerksam, dass Falschangaben strafrechtlich geahndet werden konnen. Auf folgenden Tatbestande kann die Haftung auf Sie personlich rubergehen. -->
                                            </p>
                                        </div>
                                        <div v-else
                                             style="background: #efefef; padding: 10px"
                                             class="mt-1"
                                        >
                                            <p>
                                                {{$t("preregistered_company_payment")}}
                                                <br/>
                                                {{$t("preregistered_company_payment_2")}}
                                            </p>
                                        </div>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card>

                        <h5>{{ $t("legal_notice") }}</h5>
                        <b-card>
                            <b-row m-0 p-0>
                                <b-col cols="12">
                                    <!-- terms-and-conditions -->
                                    <b-form-group>
                                        <validation-provider
                                            #default="{ errors }"
                                            name="terms-and-conditions"
                                            rules="required"
                                        >
                                            <b-form-checkbox
                                                id="terms-and-conditions"
                                                v-model.trim="terms_and_conditions"
                                                name="terms-and-conditions"
                                                :state="errors.length > 0 ? false : null"
                                                :disabled="disableInputs"
                                            >
                                                {{
                                                $t("agree_with_terms_&_conditions_seller_before_link")
                                                }}

                                                <span class="web">
                          <a @click="toTerms()">{{
                              $t("agree_with_terms_&_conditions_seller_link")
                            }}</a>

                        </span>
                                                <span class="mobile">
                          <a @click="toTerms()">{{
                            $t("agree_with_terms_&_conditions_seller_link")
                          }}</a>
                        </span>
                                            </b-form-checkbox>
                                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                                        </validation-provider>
                                        <div
                                            style="background: #efefef; padding: 10px"
                                            class="mt-1"
                                        >
                                            <p>
                                                {{
                                                $t("agree_with_terms_&_conditions_seller_subtitle")
                                                }}

                                                <!-- Diese AGB's gelten. Wir machen Sie insbesondere darauf aufmerksam, dass Falschangaben strafrechtlich geahndet werden konnen. Auf folgenden Tatbestande kann die Haftung auf Sie personlich rubergehen. -->
                                            </p>
                                            <p class="col-md-12">
                                                {{ $t("agree_with_terms_&_conditions_seller_1") }}

                                                <!-- Diese AGB's gelten. Wir machen Sie insbesondere darauf aufmerksam, dass Falschangaben strafrechtlich geahndet werden konnen. Auf folgenden Tatbestande kann die Haftung auf Sie personlich rubergehen. -->
                                            </p>
                                            <p class="col-md-12">
                                                {{ $t("agree_with_terms_&_conditions_seller_2") }}
                                            </p>
                                        </div>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card>
                        <b-row m-0 p-0 class="col-12 text-end justify-content-end" align="end">
                            <br>
                            <span v-if="waitForSubmit" class="mr-1">
                      <div v-if="currentLanguage == 'en'" class="alert alert-secondary p-1">
                        Please be patient, loading the data might need 5 until 10 seconds.
                      </div>
                      <div v-else-if="currentLanguage == 'de'" class="alert alert-secondary p-1">
                        Bitte haben Sie etwas Geduld, das Laden der Daten kann 5 bis 10 Sekunden dauern
                      </div>
                    </span>
                            <div class="text-right">
                                <img v-if="resLoader" width="50" height="50" src="/new-loader.svg"/>
                                <b-button :disabled="resLoader" variant="primary" @click="saveCompanyInfo($event)">
                                    <feather-icon icon="SaveIcon"></feather-icon>
                                    {{ $t("save_company_information") }}
                                </b-button>
                            </div>
                            <br>

                        </b-row>

                    </div>
                </b-form>
            </validation-observer>
        </div>
    </div>
</template>

<script>
  import {
    ValidationProvider,
    ValidationObserver,
    Validator,
    extend,
  } from "vee-validate";
  import {required, email} from "@validations";
  import {VueAutosuggest} from "vue-autosuggest";
  import {phone} from "phone";

  import {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckbox,
    BAlert,
    BInputGroupPrepend,
  } from "bootstrap-vue";
  import ToastificationContent from "@core/components/toastification/ToastificationContent";
  import Cleave from "vue-cleave-component";
  import {setTimeout} from "core-js";

  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BForm,
      BButton,
      BFormGroup,
      BFormSelect,
      BFormInput,
      BInputGroup,
      BFormCheckbox,
      BFormDatepicker,
      BFormRadio,
      BFormRadioGroup,
      BAlert,
      BInputGroupPrepend,
      ValidationProvider,
      ValidationObserver,
      VueAutosuggest,
      Cleave,
    },
    data() {
      return {
        selected_currency: null,
        currencies: [
          {
            value: '1', text: 'CHF'
          },
          {
            value: '2', text: 'EUR'
          }
        ],
        timeout: null,
        phoneContries: "CH",
        filteredOptions: [],
        showLoader: false,
        disableInputs: false,
        country_id: "Switzerland",
        countries: [],
        company_id: this.$store.state.auth.user.company_id,
        company_name: "",
        company_name_after: "",
        companies: [],
        company_zefix_data: null,
        company_address: "",
        company_address_nr: "",
        company_uid: "",
        company_zip: "",
        company_city: "",
        company_legal_form: "",
        company_website: "",
        preregistered: false,
        emailValidationsError: "",
        phoneValidation: "",
        showRestData: false,
        alreadyExists: "",
        legal_forms: [
          {
            value: 0,
            text: "sole_proprietorship",
          },
          {
            value: 1,
            text: "general_partnership",
          },
          {
            value: 2,
            text: "limited_partnership",
          },
          {
            value: 3,
            text: "corporation",
          },
          {
            value: 4,
            text: "limited_liability_company",
          },
          {
            value: 5,
            text: "cooperative",
          },

        ],
        company_established_at: "",
        number_of_employees: "",
        annual_turnover: "",
        intent_to_sell: "31% - 50%",
        number_of_debtors: "21-50",
        debt_enforcement: "No",
        intent_to_sell_values: [
          "1% - 10%",
          "11% - 20%",
          "21% - 30%",
          "31% - 50%",
          "51% - 70%",
          "71% - 80%",
          "81% - 90%",
          "91% - 100%",
        ],
        number_of_debtors_values: [
          "1-10",
          "11-20",
          "21-50",
          "51-100",
          "101-200",
          "201-500",
          "500+",
        ],
        debt_enforcement_values: ["Yes", "No"],
        main_contact_gender: "",
        main_contact_email: this.$store.state.auth.user.email,
        main_contact_first_name: this.$store.state.auth.user.first_name,
        main_contact_last_name: this.$store.state.auth.user.last_name,
        main_contact_tel: "",
        main_contact_position: "",
        signing_authority_type: "",
        signing_authority_type_values: ["Sole signatory", "Collectively by two"],
        signators: [],
        terms_and_conditions: "",
        data_privacy: "",
        hideSigners: false,
        successMessage: false,
        showBlueBorder: false,
        errors: [],
        showTransactions: false,
        bank_name: "",
        bank_address: "",
        iban: "",
        reference_number: "",
        language: 'de',
        phoneSignator: "",
        phoneSignatorValidation: "",
        companyMessage: "",
        showHint: false,
        fee_policy: false,
        invalidIbanError: false,
        ibanMessage: "",
        phoneOptions: {
          phone: {
            delimiters: [" ", " ", " "],
            blocks: [2, 3, 2, 2],
          },
        },
        resLoader: false,
        completedFunction: false,
        completedSelectDebtor: false,
        waitForSubmit: false,
        waitForDebtorData: false,
      };
    },
    name: "AddCompany",
    computed: {
      currentLanguage() {
        return this.$store.state.app.language;
      },
    },
    mounted() {
      this.updateLegalForms(this.currentLanguage);
    },
    watch: {
      currentLanguage(newLanguage) {
        this.updateLegalForms(newLanguage);
      },
    },
    created() {

      if (this.currencies.length > 0) {
        this.selected_currency = this.currencies[0].value;
      }
      this.$http.post("/countries").then((res) => {
        res.data.value.map((item) => {
          this.countries.push({
            value: item.iso_code,
            text: `${item.iso_code} (${item.phone_code})`,
          });
        });
      });
    },
    methods: {

      getCountry() {
        this.$http.post(`/countries/${this.company_country_id}`).then((res) => {
          this.country_id = res.data.value.name
        });
      },

      openLink() {
        window.open("https://support-aequitex.zohobookings.eu/#/aequitex/booknow", "_blank");
      },
      toTerms() {
        if (this.$i18n.locale == "en") {
          window.open("https://www.aequitex.com/terms-condition", "_blank");
        } else {
          window.open("https://www.aequitex.com/de/bedingungen-und-konditionen", "_blank");
        }
      },
      updateLegalForms(language) {
        this.legal_forms.forEach((form) => {
          form.text = this.$t(form.text, {locale: language});
        });
      },
      validateEmail(val) {
        this.emailValidationsError = "";
        this.$http.post("company/checkEmail", {email: val}).then((res) => {
          if (res) {
            if (res.data.value.email) {
              this.emailValidationsError = this.$t("email_already_exist");
            }
          }
        });
      },
      checkPhoneFormatSignator(val, code) {
        if (val) {
          let validation = phone(val, {
            country: code,
            validateMobilePrefix: false
          });
          if (validation && validation.isValid == false) {
            this.phoneSignatorValidation = `Phone for ${code} must be valid`;
          } else {
            this.phoneSignatorValidation = "";
          }
        }
      },
      checkPhoneFormat() {
        let validation = phone(this.main_contact_tel, {
          country: this.phoneContries,
          validateMobilePrefix: false
        });

        if (validation && validation.isValid == false) {
          this.phoneValidation = `Phone for ${this.phoneContries} must be valid`;
        } else {
          this.phoneValidation = "";
        }
      },
      closeTransactions() {
        this.showTransactions = false;
      },
      toggleDarkMode() {
        let color = "";
        if (this.$store.state.verticalMenu.lightMode == "light") {
          color = "background:white";
        } else {
          color = "background:#283046";
        }
        return color;
      },
      hideSignersPart() {
        if (this.signing_authority_type == "0" || this.signing_authority_type == "2") {
          this.hideSigners = false;
          this.signators = [];
        } else if (
            this.signing_authority_type != "0" &&
            this.signators.length < 1
        ) {
          this.signators.push({
            gender: "Mr",
            first_name: "",
            email: "",
            last_name: "",
            tel: "",
            position: "",
            signing_authority_type: "",
            iso_code: "CH",
          });
          this.hideSigners = true;
        }
      },
      saveCompanyInfo() {
        this.completedFunction = false;
        this.waitForSubmit = true;
        this.saveCompany();

        setTimeout(() => {
          if (this.completedFunction == false) {
            this.saveCompany();
          }
        }, 6000);

      },
      async saveCompany(event) {
        this.resLoader = true;
        this.errors = [];

        try {
          const success = await this.$refs.companyRegisterValidation.validate();

          if (this.terms_and_conditions !== true) {
            this.terms_and_conditions = "";
            this.$refs.companyRegisterValidation.validate();
            return '';
          }
          if (this.data_privacy !== true) {
            this.data_privacy = "";
            this.$refs.companyRegisterValidation.validate();
            return '';
          }

          if (
              success &&
              this.phoneValidation.length < 1 &&
              this.emailValidationsError.length < 1
          ) {
            this.disableInputs = true;

            const formSubmissionData = {
              capital_currency_id: this.selected_currency,
              company_id: this.company_id,
              company_name: this.company_name_after,
              company_address: this.company_address,
              company_address_nr: this.company_address_nr,
              company_uid: this.company_uid,
              company_zip: this.company_zip,
              company_city: this.company_city,
              company_country_id: this.company_country_id,
              legal_form: this.company_legal_form,
              main_contact_person_gender: this.main_contact_gender,
              main_contact_first_name: this.main_contact_first_name,
              main_contact_last_name: this.main_contact_last_name,
              main_contact_position: this.main_contact_position,
              main_contact_tel: this.main_contact_tel,
              signing_authority_type: this.signing_authority_type,
              established_at: this.company_established_at,
              number_of_employees: Number(this.number_of_employees),
              annual_turnover: Number(this.annual_turnover),
              debt_enforcement: this.debt_enforcement,
              signers: this.signators.length,
              terms_and_conditions: this.terms_and_conditions,
              data_privacy: this.data_privacy,
              placeholder: 1,
              preregistered: this.preregistered,
              iso_code: this.phoneContries,
            };

            let data = {};
            this.signators.map((item, i) => {
              const nr = i + 1;
              const signator = {
                ["signator_" + nr + "_gender"]: item.gender,
                ["signator_" + nr + "_first_name"]: item.name,
                ["signator_" + nr + "_last_name"]: item.last_name,
                ["signator_" + nr + "_email"]: item.email,
                ["signator_" + nr + "_tel"]: item.tel,
                ["signator_" + nr + "_position"]: item.position,
                ["signator_" + nr + "_iso_code"]: item.iso_code,
                ["signator_" + nr + "_signing_authority_type"]:
                item.signing_authority_type,
              };
              data = {...data, ...signator};
            });

            data = {...data, ...formSubmissionData};

            const res = await this.$http.post("/company/store", data);

            let mess = "";
            let icon = "";
            let variant = "";
            this.resLoader = false;
            if (res) {
              this.completedFunction = true;
              this.waitForSubmit = false;
            }
            if (res && res.data.status == 500) {
              mess = res.data.message;
              icon = "ErrorIcon";
              variant = "error";
            } else {
              mess = "company_registered_successfully";
              icon = "SuccessIcon";
              variant = "success";
            }

            await this.$http.post("/company/update/language", {
              language: this.language,
            });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t(mess),
                icon: icon,
                variant: variant,
              },
            });

            document.getElementById("show-settings").click();
            setTimeout(() => {
              if (typeof res.data.errors !== 'undefined') {
                if (res.data.errors.length > 0) {
                  this.errors = res.data.errors;
                  if (this.errors.length > 0) {
                    const err = document.getElementById("alert-errors");
                    setTimeout(() => {
                      err.scrollIntoView({behavior: "smooth", block: "center"});
                    }, 500);
                  }
                } else {
                  this.$router.push("/");
                }
              } else {
                this.$router.push("/");
              }
              this.disableInputs = false;
            }, 1000);
          } else {
            const el = document.getElementsByClassName("is-invalid");
            if (el) {
              setTimeout(() => {
                el[0].scrollIntoView({behavior: "smooth", block: "center"});
              }, 500);
            } else if (this.errors.length > 0) {
              const err = document.getElementById("alert-errors");
              setTimeout(() => {
                err.scrollIntoView({behavior: "smooth", block: "center"});
              }, 500);
            }
          }
        } catch (error) {
          console.error("Error:", error);
          this.resLoader = false;
        } finally {
          this.resLoader = false;
        }
      },
      checkIban() {
        this.$http
            .get(`/bank/${this.iban}`, {
              search: this.companies[this.company_name],
            })
            .then((res) => {
              if (res.data) {
                if (res.data.result === 200) {
                  this.bank_name = res.data.data.bank.bank_name;
                  this.bank_address = res.data.data.bank.address + ', ' + res.data.data.bank.zip + ', ' + res.data.data.bank.city;
                  this.invalidIbanError = false;
                } else {
                  this.invalidIbanError = true;
                }
              }
            });
      },
      deleteSignator(index) {
        this.signators.splice(index, 1);
      },
      onSelected() {
        this.successMessage = false;
      },
      getSuggestionValue(value) {
        this.company_name = value.item;
      },
      searchForCompaniesName() {
        this.alreadyExists = false;
        this.showRestData = false;
        clearTimeout(this.timeout);
        clearTimeout(this.timeout);
        const self = this;
        this.companyMessage = "";
        this.timeout = setTimeout(function () {
          if (self.company_name.length > 2) {
            self.showHint = true;
            self.companies = [];
            self.filteredOptions = [];
            self.$http
                .post("/get-companies-name-from-zefix", {
                  search: self.company_name,
                })
                .then((res) => {
                  console.log(15151, res);
                  self.successMessage = false;
                  self.filteredOptions.push({data: Object.keys(res.data.value)});
                  self.companies = res.data.value;
                  self.showHint = false;
                  if (res.data.value.length < 1) {
                    self.companyMessage = true;
                  }
                });
          } else {
            self.companies = [];
            self.filteredOptions = [];
          }
        }, 1000);
      },
      getCompanyDataFromZefix() {
        this.waitForDebtorData = true;
        this.completedSelectDebtor = false;
        this.getCompanyDataFromZefix2();

        setTimeout(() => {
          if (this.completedSelectDebtor == false) {
            this.getCompanyDataFromZefix2();
          }
        }, 6000);
      },
      async getCompanyDataFromZefix2() {
        this.showLoader = true;
        this.alreadyExists = "";

        try {
          if (typeof this.companies[this.company_name] !== "undefined") {
            const res = await this.$http.post("/get-company-data-from-zefix", {
              search: this.companies[this.company_name],
            });

            if (res) {
              this.completedSelectDebtor = true;
              this.waitForDebtorData = false;
            }
            if (res.data && res.data.status === 200) {
              console.log(456423, res);
              // if (res.data.value && res.data.value.main_contact_user_exist) {
                if (res.data.value && res.data.value.company_exist) {
                  this.showRestData = false;
                  this.showLoader = false;
                  this.alreadyExists =
                      "please_use_this_company_credentials_because_it_already_exists";
                  let message = '';
                  if (this.$store.state.app.language == 'en') {
                    message = 'Sorry, but this company is already registered.'
                  } else if (this.$store.state.app.language == 'de') {
                    message = 'Entschuldigung, aber dieses Unternehmen hat sich bereits registriert.'
                  }
                  this.$swal({
                    position: "center",
                    icon: "error",
                    title: message,
                    showConfirmButton: false,
                    timer: 1500,
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                  return;
                // }
              }
              this.company_zefix_data = res.data.value;
              if(this.company_zefix_data.company_create_role != 'admin') {
                  this.company_uid = this.company_zefix_data.uid;
                  this.company_name_after = this.company_zefix_data.name;
                  this.company_address = this.company_zefix_data.address.street;
                  this.company_address_nr = this.company_zefix_data.address.houseNumber;
                  this.company_city = this.company_zefix_data.address.city;
                  this.company_zip = this.company_zefix_data.address.swissZipCode;
                  this.company_legal_form = this.company_zefix_data.legalForm ? this.company_zefix_data.legalForm.id : '';
                  this.company_established_at = this.company_zefix_data.sogcDate;
                  this.preregistered = this.company_zefix_data.preregistered;
                  this.company_country_id = this.company_zefix_data.country_id
                    this.getCountry()
              } else {

                this.company_uid = this.company_zefix_data.uid;
                this.company_name_after = this.company_zefix_data.name;
                this.company_address = this.company_zefix_data.address;
                this.company_address_nr = this.company_zefix_data.address_nr;
                this.company_city = this.company_zefix_data.city;
                this.company_zip = this.company_zefix_data.zip;
                this.company_legal_form = this.company_zefix_data.legalForm ? this.company_zefix_data.legalForm.id : '';

              }
              this.showLoader = false;
              this.successMessage = true;
              this.showBlueBorder = true;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Data received successfully",
                  icon: "SuccessIcon",
                  variant: "success",
                },
              });
              this.showRestData = true;
            } else {
              this.showLoader = false;
              this.$swal({
                position: "center",
                icon: "error",
                title: this.$t(res.data.message),
                showConfirmButton: false,
                timer: 1500,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
          }
        } catch (error) {
          this.showLoader = false;
          console.error("Error:", error);
          if (error.response && error.response.status === 0) {
            this.$swal({
              position: "center",
              icon: "error",
              title: 'Failed to load data due to CORS issue. Click again to retry.',
              showConfirmButton: true,
              showCancelButton: true,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        }
      }
      ,

      addNewSigner() {
        if (this.signators.length < 5) {
          this.signators.push({
            gender: "Mr",
            first_name: "",
            email: "",
            last_name: "",
            tel: "",
            position: "",
            signing_authority_type: "",
            iso_code: "CH",
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "You can add only up to 5 Signatory!",
              icon: "ErrorIcon",
              variant: "error",
            },
          });
        }
      },
      onSubmit(event) {
        event.preventDefault();
        alert(JSON.stringify(this.form));
      },
      onReset(event) {
        event.preventDefault();
        this.country_id = 0;
        this.countries = [];
      },
    },
  };
</script>

<style>
    #phoneCountries select {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
</style>
<style lang="scss">
    @import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>
