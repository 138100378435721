<template>

    <b-container>
        <b-card>

            <b-card-header>
                <h3 class="text-center">{{ $t('fill_online_form_page_title') }}</h3>
            </b-card-header>
            <b-card-body>
                <b-form @submit.prevent="submitOnlineFormData">
                    <b-form-group :label="$t('contracting_partner')" class="mb-4 w-100" label-for="contracting_partner">
                        <validation-provider
                            #default="{ errors }"
                            name="contracting partner"
                            rules="required"
                        >
                            <b-form-textarea
                                id="contracting_partner"
                                required
                                rows="3"
                                :value="CompanyData.name + ' ' +
                                        CompanyData.legal_Form + ' ' +
                                        CompanyData.address + ' ' +
                                        CompanyData.address_nr + ' ' +
                                        CompanyData.zip + ' ' +
                                        CompanyData.country.name + ' '
                                "
                                disabled="true"
                            >

                            </b-form-textarea>
                            <small class="text-danger">{{ $t(errors[0]) }}</small>
                        </validation-provider>
                    </b-form-group>


                    <!-- first checkboxes -->
                    <b-form-group :disabled="disableAllInputs">
                        <b-form-checkbox class="mb-2" value="answer1" v-model="submitData.question1">
                            {{ $t('checkbox_1') }}
                        </b-form-checkbox>
                        <b-form-checkbox class="mb-2" value="answer2" v-model="submitData.question1">
                            {{ $t('checkbox_2') }}
                        </b-form-checkbox>
                        <b-form-checkbox value="answer3 " v-model="submitData.question1">
                            {{ $t('checkbox_3') }}
                        </b-form-checkbox>
                    </b-form-group>

                    <!-- last name, first name, address fields -->
                    <div class="add_input_container w-100">
                        <div :key="index" class="my-5" v-for="(additionalField, index) in submitData.additionalUsers">
                            <b-form-group :label="$t('first_name')" :label-for="'new_first_name_' + index">
                                <validation-provider #default="{ errors }" name="First name" rules="required">
                                    <b-form-input :id="'new_first_name_' + index" required v-model="additionalField.firstName" :disabled="disableAllInputs"></b-form-input>
                                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                                </validation-provider>
                            </b-form-group>
                            <b-form-group :label="$t('last_name')" :label-for="'new_last_name_' + index">
                                <validation-provider #default="{ errors }" name="Last name" rules="required">
                                    <b-form-input :id="'new_last_name_' + index" required v-model="additionalField.lastName" :disabled="disableAllInputs"></b-form-input>
                                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                                </validation-provider>
                            </b-form-group>
                            <b-form-group :label="$t('actual_address')" :label-for="'new_actual_address_' + index">
                                <validation-provider #default="{ errors }" name="Actual address" rules="required">
                                    <b-form-input :id="'new_actual_address_' + index" required v-model="additionalField.actualAddress" :disabled="disableAllInputs"></b-form-input>
                                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                                </validation-provider>
                            </b-form-group>

                        </div>
                        <div class="my-3" v-if="!disableAllInputs">
                            <b-button @click="FilladditionalUsersArray" variant="primary">{{ $t('add_new') }}</b-button>
                        </div>
                    </div>

                    <h4>{{ $t('fiduciary_holding_assets') }}</h4>

                    <b-form-group class="mt-2 mb-2" label="Is a third person the beneficial owner of the assets held in the account/securities account?">
                        <b-form-radio-group name="fiduciary-holding" required v-model="submitData.question2" :disabled="disableAllInputs">
                            <b-form-radio class="mt-1" name="k1" value="no">{{ $t('no') }}</b-form-radio>
                            <br>
                            <b-form-radio class="mt-1" name="k1" value="yes">{{ $t('yes') }}</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>

                    <!--first radio button check yes -->
                    <template v-if="submitData.question2 == 'yes'">
                        <div class="container mt-5">
                            <h2 class="mb-2">Establishment of the Beneficial Owner's Identity</h2>
                            <b-form-group :label="$t('contracting_partner')" class="mb-4 w-100" label-for="contracting_partner">
                                <validation-provider
                                    #default="{ errors }"
                                    name="contracting partner"
                                    rules="required"
                                >
                                    <b-form-textarea
                                        id="contracting_partner"
                                        required
                                        rows="3"
                                        :value="CompanyData.name + ' ' +
                                                CompanyData.legal_Form + ' ' +
                                                CompanyData.address + ' ' +
                                                CompanyData.address_nr + ' ' +
                                                CompanyData.zip + ' ' +
                                                CompanyData.country.name + ' '
                                        "
                                        disabled="true"
                                    >
                                        <!--:value="CompanyData"-->

                                    </b-form-textarea>
                                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                                </validation-provider>
                            </b-form-group>

                            <b-form-group class="mt-2 mb-4" label="The contracting partner herewith declares, (tick the appropriate box)">
                                <b-form-radio-group name="declaration" required v-model="submitData.question4" :disabled="disableAllInputs">
                                    <b-form-radio class="mt-1" name="d2" value="contracting_party">that the contracting
                                        party is the
                                        beneficial owner of the assets involved
                                    </b-form-radio>
                                    <br>
                                    <b-form-radio class="mt-1" name="d2" value="following_person">that the following
                                        person(s) is/are the
                                        beneficial owner(s) of the assets involved:
                                    </b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>

                            <div v-if="submitData.question4 == 'following_person'">

                                <b-form-group :label="$t('last_name')" label-for="beneficial_owner_last_name">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Last name"
                                        rules="required"
                                    >
                                        <b-form-input
                                            id="beneficial_owner_last_name"
                                            required
                                            v-model="submitData.beneficialOwner.lastName"
                                            :disabled="disableAllInputs"
                                        ></b-form-input>
                                        <small class="text-danger">{{ $t(errors[0]) }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <b-form-group :label="$t('fist_name')" label-for="beneficial_owner_last_name">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="First name"
                                        rules="required"
                                    >
                                        <b-form-input
                                            id="beneficial_owner_fist_name"
                                            required
                                            v-model="submitData.beneficialOwner.firstName"
                                            :disabled="disableAllInputs"
                                        ></b-form-input>
                                        <small class="text-danger">{{ $t(errors[0]) }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <b-form-group :label="$t('birthday')" label-for="beneficial_owner_bod">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Date of Birth"
                                        rules="required"
                                    >
                                        <b-form-input
                                            id="beneficial_owner_bod"
                                            required
                                            type="date"
                                            v-model="submitData.beneficialOwner.dateOfBirth"
                                            :disabled="disableAllInputs"
                                        ></b-form-input>
                                        <small class="text-danger">{{ $t(errors[0]) }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <b-form-group :label="$t('nationality')" label-for="beneficial_owner_nationality">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Nationality"
                                        rules="required"
                                    >
                                        <b-form-input
                                            id="beneficial_owner_nationality"
                                            required
                                            v-model="submitData.beneficialOwner.nationality"
                                            :disabled="disableAllInputs"
                                        ></b-form-input>
                                        <small class="text-danger">{{ $t(errors[0]) }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <b-form-group :label="$t('address')" label-for="beneficial_owner_address">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Address"
                                        rules="required"
                                    >
                                        <b-form-input
                                            id="beneficial_owner_address"
                                            required
                                            v-model="submitData.beneficialOwner.address"
                                            :disabled="disableAllInputs"
                                        ></b-form-input>
                                        <small class="text-danger">{{ $t(errors[0]) }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <b-form-group :label="$t('country')" label-for="beneficial_owner_country">
                                    <validation-provider
                                        #default="{ errors }"
                                        name="Country"
                                        rules="required"
                                    >
                                        <b-form-input
                                            id="beneficial_owner_country"
                                            required
                                            v-model="submitData.beneficialOwner.country"
                                            :disabled="disableAllInputs"
                                        ></b-form-input>
                                        <small class="text-danger">{{ $t(errors[0]) }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </div>

                        </div>

                    </template>

                    <!-- first radio button check no -->

                    <b-form-group :label="$t('radio_text_2')" class="mt-2 mb-4" v-else-if="submitData.question2 == 'no'">
                        <b-form-radio-group name="manage-account" required v-model="submitData.question3" :disabled="disableAllInputs">
                            <b-form-radio class="mt-1" name="d2" value="no">{{ $t('no') }}</b-form-radio>
                            <br>
                            <b-form-radio class="mt-1" name="d2" value="yes">{{ $t('yes') }}</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>

                    <!-- second radio button check no -->

                    <div class="mb-5" v-if="submitData.question2 == 'no' && submitData.question3 == 'no'">
                        <b-form-group :label="$t('last_name')" label-for="last_name">
                            <validation-provider
                                #default="{ errors }"
                                name="Last name"
                                rules="required"
                            >
                                <b-form-input
                                    id="last_name"
                                    required
                                    v-model="submitData.accountManage.firstName"
                                    :disabled="disableAllInputs"
                                ></b-form-input>
                                <small class="text-danger">{{ $t(errors[0]) }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group :label="$t('first_name')" label-for="first_name">
                            <validation-provider
                                #default="{ errors }"
                                name="First name"
                                rules="required"
                            >
                                <b-form-input
                                    id="first_name"
                                    required
                                    :disabled="disableAllInputs"
                                    v-model="submitData.accountManage.lastName"
                                ></b-form-input>
                                <small class="text-danger">{{ $t(errors[0]) }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group :label="$t('actual_address')" label-for="actual_address">
                            <validation-provider
                                #default="{ errors }"
                                name="Actual address"
                                rules="required"
                            >
                                <b-form-input
                                    id="actual_address"
                                    required
                                    v-model="submitData.accountManage.address"
                                    :disabled="disableAllInputs"
                                ></b-form-input>
                                <small class="text-danger">{{ $t(errors[0]) }}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>

                    <b-form-group>
                        <p>
                            {{ $t('register_3a_text1') }}
                        </p>
                        <p>
                            {{ $t('register_3a_text2') }}
                        </p>
                    </b-form-group>

                    <b-button class="mt-4" type="submit" variant="primary">Submit</b-button>
                </b-form>
            </b-card-body>

        </b-card>
    </b-container>


</template>

<script>

  import {ValidationProvider} from "vee-validate";
  import {required} from "@validations";
  import {
    BAlert,
    BButton,
    BCard,
    BCol,
    BForm,
    BFormCheckbox,
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormRadioGroup,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
    BRow,
  } from "bootstrap-vue";


  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BForm,
      BButton,
      BFormGroup,
      BFormSelect,
      BFormInput,
      BFormCheckbox,
      BFormDatepicker,
      BFormRadio,
      BFormRadioGroup,
      BAlert,
      BInputGroup,
      BInputGroupPrepend,
      ValidationProvider,
    },
    name: "FillStep1",
    data() {
      return {
        // CompanyData: this.$store.state.auth.user.company,
        CompanyData: null,
        disableAllInputs: false,
        // CompanyData: {
        //     name: null,
        //     legalForm: null,
        //     address: null,
        //     addressNr: null,
        //     zip: null,
        //     country: null,
        //     city: null
        // },
        submitData: {
          question1: null,
          question2: null,
          question3: null,
          question4: null,
          additionalUsers: [],

          accountManage: {
            firstName: null,
            lastName: null,
            address: null,
          },

          beneficialOwner: {
            firstName: null,
            lastName: null,
            dateOfBirth: null,
            nationality: null,
            address: null,
            country: null,
          }
        }
      }
    },
    created() {
      this.FilladditionalUsersArray();
      this.getOnlineForm();
      this.getCompany();
    },

    methods: {
        FilladditionalUsersArray() {
            this.submitData.additionalUsers.push({
                firstName: '',
                lastName: '',
                actualAddress: ''
            });
        },

        submitOnlineFormData() {
            this.$http
                .post("/online_form/store", this.submitData)
                .then((res) => {
                    if (res.data.status == 200) {
                        this.$router.push({name: 'home'})
                    }
                });
        },

        getOnlineForm() {
            this.$http
                .post("/online_form")
                .then((res) => {
                    if (res.data && res.data.value) {
                        this.submitData = res.data.value
                        this.disableAllInputs = true
                    }
                })
        },

      getCompany() {
            this.$http
                .post("/online_form/get_company")
                .then((res) => {
                    if (res.data && res.data.value) {
                        this.CompanyData = res.data.value
                    }
            })
        }

    }

  }
</script>

<style scoped>

</style>